import './unlock.scss';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';

const checkPassword = async (pw) => {
	let result = false;
	try {
		const response = await axios.post('/api/unlock', { pw });
		result = response.data.success;
	} catch (err) {
		console.log(err);
	}

	return result;
};

function Unlock() {
	useEffect(() => {
		document.body.style.cssText = `
		  position: fixed; 
		  top: -${window.scrollY}px;
		  overflow-y: scroll;
		  width: 100%;`;
		return () => {
			const scrollY = document.body.style.top;
			document.body.style.cssText = '';
			window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
		};
	}, []);

	const [password, setPassword] = useState('');
	const [correct, setCorrect] = useState('');
	const [validated, setValidated] = useState(false);
	const [isValid, setIsValid] = useState(false);

	const onPasswordHandler = (e) => {
		setPassword(e.target.value);
	};

	const onSubmitHandler = async (e) => {
		e.preventDefault();
		let result = await checkPassword(password);
		if (true === result) {
			setCorrect('correct');
			setIsValid(false);
			document.body.style.cssText = '';
		} else {
			setCorrect('');
			setIsValid(true);
		}
	};

	return (
		<div id='unlock-contents' className={correct}>
			<div className='unlock-main'>
				<div className='unlock-main-logo' />
				<Form onSubmit={onSubmitHandler} noValidate validated={validated}>
					<Form.Group className='mb-3' controlId='formBasicPassword'>
						<Form.Control
							required
							className={isValid ? 'is-invalid' : ''}
							type='password'
							placeholder='Password'
							onChange={onPasswordHandler}
						/>
					</Form.Group>
					<Button variant='primary' type='submit'>
						Submit
					</Button>
				</Form>
			</div>
		</div>
	);
}

export default Unlock;

import './App.css';
import './reset.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Routes, Route } from 'react-router-dom';

import Navi from './layout/navi';
import Footer from './layout/footer';

import Home from './routes/home';
import Menu from './routes/menu';
import Find from './routes/find';
import Shop from './routes/shop';
import Catering from './routes/catering';
import Franchising from './routes/franchising';
import Press from './routes/press';
import Events from './routes/events';
import About from './routes/about';
import Work from './routes/work';
import Contact from './routes/contact';

import Unlock from './unlock/unlock';

import { setCookie, getCookie } from './cookie/cookie.js';
import Initi18n from './i18n/i18n.js';
import MetaTag from './metatag';

function App() {
	let langcode = 'en';
	if (getCookie('lang-code') === undefined) {
		setCookie('lang-code', 'en', { maxAge: 31536000 });
	} else {
		langcode = getCookie('lang-code');
	}
	Initi18n(langcode);

	return (
		<div className='App'>
			<MetaTag
				title='Krikey Eats'
				description='Krikey Eats Website.'
				url='https://krikeyeats.com/'
			/>

			<Unlock />

			<Navi />

			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/menu' element={<Menu />} />
				<Route path='/find' element={<Find />} />
				<Route path='/shop' element={<Shop />} />
				<Route path='/catering' element={<Catering />} />
				<Route path='/franchising' element={<Franchising />} />
				<Route path='/press' element={<Press />} />
				<Route path='/events' element={<Events />} />
				<Route path='/about' element={<About />} />
				<Route path='/work' element={<Work />} />
				<Route path='/contact' element={<Contact />} />
			</Routes>

			<Footer />
		</div>
	);
}

export default App;

import '../reset.scss';
import './find.scss';

import { Row, Col, Button } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

import { useTranslation } from 'react-i18next';

function Find() {
	const { t } = useTranslation();

	return (
		<>
			<div id='findus-contents'>
				<div className='findus-main'>
					<h1>FIND US</h1>
					<div className='findus-locator'>
						<div className='findus-locator-map' />
						<div className='findus-locator-list'>
							<div className='findus-locator-item'>
								<h4 className='findus-locator-item-title'>
									Misa Store (Flagship)
								</h4>
								<p className='findus-locator-item-phone'>031) 123 4567</p>
								<p className='findus-locator-item-time'>10am to 8pm</p>
								<p className='findus-locator-item-address'>
									100, Misagangbyeondong-ro, Hanam-si, Gyeonggi-do, Republic of
									Korea
								</p>
							</div>
							<div className='findus-locator-item'>
								<h4 className='findus-locator-item-title'>
									Starfield Hanam Store
								</h4>
								<p className='findus-locator-item-phone'>031) 743 1527</p>
								<p className='findus-locator-item-time'>10am to 8pm</p>
								<p className='findus-locator-item-address'>
									750, Misa-daero, Hanam-si, Gyeonggi-do, Republic of Korea
								</p>
							</div>
							<div className='findus-locator-item'>
								<h4 className='findus-locator-item-title'>IFC Mall Store</h4>
								<p className='findus-locator-item-phone'>02) 971 2847</p>
								<p className='findus-locator-item-time'>10am to 8pm</p>
								<p className='findus-locator-item-address'>
									10, Gukjegeumyung-ro, Yeongdeungpo-gu, Seoul, Republic of
									Korea
								</p>
							</div>
							<div className='findus-locator-item'>
								<h4 className='findus-locator-item-title'>Parnas Mall Store</h4>
								<p className='findus-locator-item-phone'>02) 489 1003</p>
								<p className='findus-locator-item-time'>10am to 8pm</p>
								<p className='findus-locator-item-address'>
									521, Teheran-ro, Gangnam-gu, Seoul, Republic of Korea
								</p>
							</div>
							<div className='findus-locator-item'>
								<h4 className='findus-locator-item-title'>
									Gangnam Severance Hospital
								</h4>
								<p className='findus-locator-item-phone'>02) 723 4567</p>
								<p className='findus-locator-item-time'>10am to 8pm</p>
								<p className='findus-locator-item-address'>
									211, Eonju-ro, Gangnam-gu, Seoul, Republic of Korea
								</p>
							</div>
							<div className='findus-locator-item'>
								<h4 className='findus-locator-item-title'>
									Asan Medical Center
								</h4>
								<p className='findus-locator-item-phone'>02) 723 4567</p>
								<p className='findus-locator-item-time'>10am to 8pm</p>
								<p className='findus-locator-item-address'>
									88, Olympic-ro 43-gil, Songpa-gu, Seoul, Republic of Korea
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Find;

import '../reset.scss';
import './home.scss';

import { Row, Col, Button } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

import { useTranslation } from 'react-i18next';

function Home() {
	const { t } = useTranslation();

	return (
		<>
			<div id='home-contents'>
				<div className='home-main'>
					<h1>{t('home-main-text')}</h1>
					<div className='home-main-logo' />
				</div>
				<div className='home-main-detail'>
					<p>Krikey Eats was built on our founder’s passion for eating,</p>
					<p>for quality, and for unrivalled excellence.</p>
					<p>We go above and beyond</p>
					<p>so that a delicious meal is made fresh and ready for you.</p>
				</div>
				<div className='home-content'>
					<HashLink to='/about#'>
						<Button variant='dark'>About Us</Button>
					</HashLink>
					<Row className='reverse'>
						<Col className='card-image'>
							<div className='home-content-image menu' />
						</Col>
						<Col>
							<h3>Well, what are you waiting for?</h3>
							<h2>Just eat and go!</h2>
							<div className='home-menu-button'>
								<HashLink to='/menu#'>
									<Button variant='dark'>Menu</Button>
								</HashLink>
								<HashLink to='/find#'>
									<Button variant='dark'>Locations</Button>
								</HashLink>
							</div>
						</Col>
						<Col className='card-image'>
							<div className='home-content-image aboutus' />
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
}

export default Home;

import '../reset.scss';
import './about.scss';

import { Row, Col, Button } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

import { useTranslation } from 'react-i18next';

function About() {
	const { t } = useTranslation();

	return (
		<>
			<div id='aboutus-contents'>
				<div className='aboutus-main'>
					<div className='aboutus-main-banner'>
						<h1>ABOUT US</h1>
					</div>

					<div className='aboutus-contents'>
						<div class='aboutus-contents-body'>
							<h1>Let's eat!</h1>
							<p>
								Founded on our love for food,
								<br />
								<br />
								our high expectation of quality not just when it comes to
								ingredients
								<br />
								but quality in everything we do,
								<br />
								<br />
								and our unwavering vision of building an exceptional business
								with
								<br />
								operational excellence at its core.
								<br />
								<br />
								Our Founder and Chief Eating Officer absolutely loves bread.
								<br />
								<br />
								She saw the opportunity to deliver a delicious, vibrant, and
								fresh
								<br />
								variety of food and experiences through Krikey Eats shops and to
								make it
								<br />
								available to all who shares this love of delightful, great
								bread.
								<br />
								<br />
								Experience it for yourself at one of our stores.
							</p>
							<HashLink to='/find#'>
								<Button type='submit' variant='dark' size='lg'>
									Find Us
								</Button>
							</HashLink>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default About;

import '../reset.scss';
import './menu.scss';

import { useState } from 'react';

import { useTranslation } from 'react-i18next';

function Menu03() {
	const { t } = useTranslation();

	return (
		<div className='menu-paragraph'>
			<div className='menu-paragraph-image menu-03' />
			<div className='menu-paragraph-content'>
				{/* MINI BAGELS */}

				<div className='menu-paragraph-content-block minibagels'>
					<h3 className='menu-paragraph-content-block-title'>MINI BAGELS</h3>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>PLAIN</p>
							<p className='menu-paragraph-content-block-detail-Price'>2,000</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								BLUEBERRY
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>3,500</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>SESAME</p>
							<p className='menu-paragraph-content-block-detail-Price'>3,500</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>GRAINY</p>
							<p className='menu-paragraph-content-block-detail-Price'>3,500</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								PRETZEL
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>4,000</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								CHOCOLATE DRIZZLE
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>4,500</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								HOT CROSS BUN
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>4,500</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								CHEESEY JALAPENO
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>3,500</p>
						</div>
						<p className='menu-paragraph-content-block-detail-Description'>
							(LIMITED EDITION)
						</p>
					</div>
				</div>

				{/* SPREADS */}

				<div className='menu-paragraph-content-block spread'>
					<h3 className='menu-paragraph-content-block-title'>SPREADS</h3>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								PLAIN CREAM CHEESE
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>1,000</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								SCALLION CREAM CHEESE
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>2,500</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								BLUEBERRY CREAM CHEESE
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>2,500</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								STRAWBERRY CREAM CHEESE
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>2,500</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								HONEY WALNUT CREAM CHEESE
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>3,000</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								STRAWBERRY JAM
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>3,000</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								NUTELLA
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>2,000</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								PEANUT BUTTER
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>2,000</p>
						</div>
					</div>
				</div>

				{/* MINI PRETZELS */}

				<div className='menu-paragraph-content-block minipretzels'>
					<h3 className='menu-paragraph-content-block-title'>MINI PRETZELS</h3>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								CLASSIC
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>3,000</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								CHEESEY
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>3,200</p>
						</div>
					</div>
					<h3 className='menu-paragraph-content-block-title'>
						BAGEL BY THE BAG
					</h3>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								4 BAGELS
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>
								10% off
							</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								6 BAGELS
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>
								15% off
							</p>
						</div>
					</div>
					<h3 className='menu-paragraph-content-block-title'>SPREADS TO GO</h3>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								MEDIUM (FOR 2 BAGELS)
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>
								5% off
							</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								LARGE (FOR 4 BAGELS)
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>
								7% off
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Menu03;

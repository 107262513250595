import '../reset.scss';
import './press.scss';

import { Row, Col, Button } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

import { useTranslation } from 'react-i18next';

function Press() {
	const { t } = useTranslation();

	return (
		<>
			<div id='press-contents'>
				<div className='press-main'>
					<div className='press-main-title'>
						<h1>Where you've seen us..</h1>
					</div>
					<div className='press-main-contents'>
						<div className='press-main-contents-article'>
							<div className='press-main-contents-article-item left dtstore'>
								<div className='press-main-contents-article-item-tag'>
									<p>STORE</p>
								</div>
								<div className='press-main-contents-article-item-title'>
									<h2>
										KRIKEY Eats opened a<br />
										DT store in Misa, Hanam.
									</h2>
								</div>
								<div className='press-main-contents-article-item-date'>
									<p>15 December, 2023</p>
								</div>
							</div>
							<div className='press-main-contents-article-item-wrap'>
								<div className='press-main-contents-article-item right donated'>
									<div className='press-main-contents-article-item-tag'>
										<p>DONATION</p>
									</div>
									<div className='press-main-contents-article-item-title'>
										<h2>
											KRIKEY Eats donated 1 billion Won to the Children's
											Foundation
										</h2>
									</div>
									<div className='press-main-contents-article-item-date'>
										<p>2 October, 2023</p>
									</div>
								</div>
								<div className='press-main-contents-article-item right pay'>
									<div className='press-main-contents-article-item-tag'>
										<p>POINT</p>
									</div>
									<div className='press-main-contents-article-item-title'>
										<h2>
											2x point earning event when paying with an affiliate card
										</h2>
									</div>
									<div className='press-main-contents-article-item-date'>
										<p>29 September, 2023</p>
									</div>
								</div>
							</div>
						</div>
						<div className='press-main-contents-sns'>
							<div className='press-main-contents-sns-item'>
								<div className='sns-item-auther'>
									<div className='sns-item-auther-image auther01' />
									<div className='sns-item-auther-name'>
										<p>FIRST_CLASS_RABBIT</p>
									</div>
								</div>
								<div className='sns-item-image instagram01' />
								<div className='sns-item-menu'>
									<div className='sns-item-menu-left'>
										<div className='sns-item-menu-button heart' />
										<div className='sns-item-menu-button comment' />
										<div className='sns-item-menu-button share' />
									</div>
									<div className='sns-item-menu-button bookmark' />
								</div>
								<div className='sns-item-contents'>
									<p className='sns-item-contents-like'>1K Likes</p>
									<p className='sns-item-contents-hash'>
										#bagel #brunch #krikey
									</p>
									<p className='sns-item-contents-date'>3 DAYS AGO</p>
								</div>
							</div>
							<div className='press-main-contents-sns-item'>
								<div className='sns-item-auther'>
									<div className='sns-item-auther-image auther02' />
									<div className='sns-item-auther-name'>
										<p>Jumping._.Cat</p>
									</div>
								</div>
								<div className='sns-item-image instagram02' />
								<div className='sns-item-menu'>
									<div className='sns-item-menu-left'>
										<div className='sns-item-menu-button heart' />
										<div className='sns-item-menu-button comment' />
										<div className='sns-item-menu-button share' />
									</div>
									<div className='sns-item-menu-button bookmark' />
								</div>
								<div className='sns-item-contents'>
									<p className='sns-item-contents-like'>5.5K Likes</p>
									<p className='sns-item-contents-hash'>
										#krikeyeats #salmon #plainbagel #awesome
									</p>
									<p className='sns-item-contents-date'>15 DAYS AGO</p>
								</div>
							</div>
							<div className='press-main-contents-sns-item'>
								<div className='sns-item-auther'>
									<div className='sns-item-auther-image auther03' />
									<div className='sns-item-auther-name'>
										<p>WORRIED__PENGUIN</p>
									</div>
								</div>
								<div className='sns-item-image instagram03' />
								<div className='sns-item-menu'>
									<div className='sns-item-menu-left'>
										<div className='sns-item-menu-button heart' />
										<div className='sns-item-menu-button comment' />
										<div className='sns-item-menu-button share' />
									</div>
									<div className='sns-item-menu-button bookmark' />
								</div>
								<div className='sns-item-contents'>
									<p className='sns-item-contents-like'>2.4K Likes</p>
									<p className='sns-item-contents-hash'>
										#sausage #krikey #delicious #spicy
									</p>
									<p className='sns-item-contents-date'>2 DAYS AGO</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Press;

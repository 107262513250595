import '../reset.scss';
import './menu.scss';

import { useState } from 'react';

import { useTranslation } from 'react-i18next';

function Menu01() {
	const { t } = useTranslation();

	return (
		<div className='menu-paragraph'>
			<div className='menu-paragraph-image menu-01' />
			<div className='menu-paragraph-content'>
				{/* BRUNCH */}

				<div className='menu-paragraph-content-block'>
					<h3 className='menu-paragraph-content-block-title'>BRUNCH</h3>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>G’Day</p>
							<p className='menu-paragraph-content-block-detail-Price'>4,500</p>
						</div>
						<p className='menu-paragraph-content-block-detail-Description'>
							Pastrami, egg, and melted cheese on a plain bagel.
						</p>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								GOOD MORNING
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>4,500</p>
						</div>
						<p className='menu-paragraph-content-block-detail-Description'>
							Sausage, egg, and melted cheese on a plain bagel.
						</p>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								RISE N' SHINE (10AM-11AM)
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>5,500</p>
						</div>
						<p className='menu-paragraph-content-block-detail-Description'>
							Plain cream cheese on a plain bagel + long black.
						</p>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								BRUNCH SPECIAL (10AM-11AM)
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>6,500</p>
						</div>
						<p className='menu-paragraph-content-block-detail-Description'>
							G ‘Day or Good Morning + long black.
						</p>
					</div>
				</div>

				{/* DESSERT */}

				<div className='menu-paragraph-content-block'>
					<h3 className='menu-paragraph-content-block-title'>DESSERTS</h3>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								THE SEOULITE
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>6,500</p>
						</div>
						<p className='menu-paragraph-content-block-detail-Description'>
							Honey walnut cream cheese and strawberry jam on a chocolate
							drizzle bagel.
						</p>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								THE MELBOURNIAN
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>6,500</p>
						</div>
						<p className='menu-paragraph-content-block-detail-Description'>
							Peanut butter and strawberry jam on a pretzel bagel.
						</p>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								THE PARISIAN
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>5,000</p>
						</div>
						<p className='menu-paragraph-content-block-detail-Description'>
							Nutella spread on a grainy bagel.
						</p>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								THE AUSSIE
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>6,500</p>
						</div>
						<p className='menu-paragraph-content-block-detail-Description'>
							Plain cream cheese and strawberry jam on a hot cross bun bagel.
						</p>
					</div>
				</div>

				{/* DRINKS */}

				<div className='menu-paragraph-content-block drinks'>
					<h3 className='menu-paragraph-content-block-title'>DRINKS</h3>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								SHORT BLACK
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>3,800</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								LONG BLACK
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>4,800</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								FLAT WHITE
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>5,500</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								CAPPUCCINO
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>5,500</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								BABYCINNO
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>1,500</p>
						</div>
						<p className='menu-paragraph-content-block-detail-Description'>
							(KIDS &lt;8 YEARS OLD)
						</p>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								PUPPACINNO
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>1,000</p>
						</div>
						<p className='menu-paragraph-content-block-detail-Description'>
							(FOR DOGS ONLY)
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Menu01;

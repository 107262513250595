import '../reset.scss';
import './menu.scss';

import { Row, Col, Button } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

import { useTranslation } from 'react-i18next';

import Menu01 from './menu01';
import Menu02 from './menu02';
import Menu03 from './menu03';
import Menu04 from './menu04';

function Menu() {
	const { t } = useTranslation();

	return (
		<>
			<div id='menu-contents'>
				<Menu01 />
				<Menu02 />
				<Menu03 />
				<Menu04 />
			</div>
		</>
	);
}

export default Menu;

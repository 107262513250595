import '../reset.scss';
import './franchising.scss';

import { Row, Col, Button } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

import { useTranslation } from 'react-i18next';

function Franchising() {
	const { t } = useTranslation();

	return (
		<>
			<div id='franchising-contents'>
				<div className='franchising-main'>
					<div className='franchising-main-wrap'>
						<div className='franchising-main-image' />
						<div className='franchising-main-body'>
							<h1>
								Wait a year!
								<br />
								We'll see ya then!
							</h1>
							<HashLink to='/contact#'>
								<Button type='submit' variant='dark' size='lg'>
									Contact Us
								</Button>
							</HashLink>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Franchising;

import '../reset.scss';
import './contact.scss';

import { Row, Col, Button, Form } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

import { useTranslation } from 'react-i18next';

function Contact() {
	const { t } = useTranslation();

	return (
		<>
			<div id='contactus-contents'>
				<div className='contactus-main'>
					<div className='contactus-main-body'>
						<h1>Got a question?</h1>
						<h3>
							Ask us by completing the form below
							<br />
							and we’ll try to get back to you as soon as we can.
						</h3>
						<div className='contactus-main-body-form'>
							<Form>
								<Row>
									<Form.Group
										className='mb-3'
										as={Col}
										controlId='cateringForm.ControlInputName'>
										<Form.Control
											required
											type='text'
											placeholder='Name'
											maxLength='20'
										/>
									</Form.Group>

									<Form.Group
										className='mb-3'
										as={Col}
										controlId='cateringForm.ControlInputPhone'>
										<Form.Control
											required
											type='text'
											placeholder='Phone'
											maxLength='20'
										/>
									</Form.Group>
								</Row>
								<Form.Group
									className='mb-3'
									as={Col}
									controlId='cateringForm.ControlInputMail'>
									<Form.Control
										required
										type='text'
										placeholder='E-Mail'
										maxLength='50'
									/>
								</Form.Group>
								<Form.Group
									className='mb-3'
									as={Col}
									controlId='cateringForm.ControlInputAdress'>
									<Form.Control
										required
										type='text'
										placeholder='Address'
										maxLength='50'
									/>
								</Form.Group>
								<Form.Group
									className='mb-3'
									controlId='cateringForm.ControlTextareaMessage'>
									<Form.Control
										required
										name='message'
										as='textarea'
										maxLength='200'
										rows={3}
										placeholder='Comments'
									/>
								</Form.Group>
								<Button type='submit' variant='dark' size='lg'>
									Send
								</Button>
							</Form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Contact;

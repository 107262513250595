import '../reset.scss';
import './work.scss';

import { Row, Col, Button } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

import { useTranslation } from 'react-i18next';

function Work() {
	const { t } = useTranslation();

	return (
		<>
			<div id='workforus-contents'>
				<div className='workforus-main'>
					<div className='workforus-main-banner'>
						<h1>WORK FOR US</h1>
					</div>

					<div className='workforus-contents'>
						<div class='workforus-contents-body'>
							<h5>
								When employment opportunities become available, we’ll post the
								job
								<br />
								description and application process right here on this page.
							</h5>
							<div class='workforus-contents-body'>
								<h3>Can’t wait to see you soon!</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Work;

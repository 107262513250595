import '../reset.scss';
import './footer.scss';

import { HashLink } from 'react-router-hash-link';

function Footer() {
	const LinkNewTab = (url) => {
		window.open(url);
	};

	return (
		<div id='footer-contents'>
			<div className='footer-nav'>
				<div className='footer-nav-item'>
					<HashLink to='/#'>HOME</HashLink>
				</div>
				<div className='footer-nav-item'>
					<HashLink to='/find#'>FIND US</HashLink>
				</div>
				<div className='footer-nav-item'>
					<HashLink to='/about#'>ABOUT US</HashLink>
				</div>
				<div className='footer-nav-item'>
					<HashLink to='/work#'>WORK FOR US</HashLink>
				</div>
				<div className='footer-nav-item'>
					<HashLink to='/contact#'>CONTACT US</HashLink>
				</div>
			</div>

			<div className='footer-content'>
				<div className='footer-content-title'>
					<p>(주) 크라이키 잇츠</p>
				</div>
				<div className='footer-content-detail'>
					<div className='footer-content-detail-item'>
						<p>대표자 구준희</p>
						<p>대표번호 031-1234-5678</p>
						<p>팩스번호 031-1234-5678</p>
					</div>
					<div className='footer-content-detail-item'>
						<p>경기도 하남시 미사강변한강로 270-1 3-124호 (12912)</p>
						<p>사업자등록번호 105-12-34567</p>
					</div>
					<div className='footer-content-detail-item'>
						<p>개인정보보호 관리책임자 구준희</p>
					</div>
				</div>
				<div className='footer-content-sns'>
					<div
						className='footer-content-sns-item instagram'
						onClick={() => LinkNewTab('https://www.instagram.com/krikeyeats')}
					/>
					<div
						className='footer-content-sns-item youtube'
						onClick={() => LinkNewTab('https://www.youtube.com/@krikeyeats')}
					/>
				</div>
				<div className='footer-content-copyright'>
					<p>© 2023 KRIKEY EATS. ALL RIGHTS RESERVED.</p>
				</div>
			</div>
			<div className='footer-logo'>
				<HashLink to='/#' className='footer-logo-img'></HashLink>
			</div>
		</div>
	);
}

export default Footer;

import '../reset.scss';
import './events.scss';

import { Row, Col, Button, Table, Pagination } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

import { useTranslation } from 'react-i18next';

function Events() {
	const { t } = useTranslation();

	return (
		<>
			<div id='events-contents'>
				<div className='events-main'>
					<div className='events-main-banner'>
						<h1>EVENTS</h1>
					</div>

					<div className='events-contents'>
						<div className='events-contents-wrap'>
							<div className='events-contents-post'>
								<div className='events-contents-post-title'>
									<h2>Happy Cheuseok 2023!</h2>
								</div>
								<div className='events-contents-post-sub'>
									<p>1 September, 2023</p>
								</div>
								<div className='events-contents-post-underline' />
								<div className='events-contents-post-img' />
								<div className='events-contents-post-text'>
									<p>
										We have a limited edition, limited quantity offer to
										celebrate Chuseok with you this year.
										<br />
										The deal is valid based on the limited quantity available
										per day.
										<br />
										<br />
										For the month of September 2023, we’ll be offering a Chuseok
										Celebration Set.
										<br />
										<br />
										Starts : 1st September, 2023
										<br />
										Ends : 30th September, 2023
										<br />
										<br />
										The Deal:
										<br />
										1. Box of 12 All Day Bagels - $$$$
										<br />
										2. Box of 12 Dessert Bagels - $$$$
									</p>
								</div>
							</div>
							<div className='events-contents-list'>
								<Table size='sm' hover>
									<thead className='table-light'>
										<tr>
											<th>Number</th>
											<th>Title</th>
											<th>Date</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>8</td>
											<td class='table-title'>Happy Valentine!</td>
											<td>14 February, 2024</td>
										</tr>
										<tr>
											<td>7</td>
											<td class='table-title'>New winter menu launch event</td>
											<td>2 February, 2024</td>
										</tr>
										<tr>
											<td>6</td>
											<td class='table-title'>
												Kakao Pay Money KRW 500 discount on a first-come,
												first-served basis
											</td>
											<td>18 January, 2024</td>
										</tr>
										<tr>
											<td>5</td>
											<td class='table-title'>
												Naver Pay point, money payment event
											</td>
											<td>12 January, 2024</td>
										</tr>
										<tr>
											<td>4</td>
											<td class='table-title'>Merry Christmas!</td>
											<td>25 December, 2023</td>
										</tr>
										<tr>
											<td>3</td>
											<td class='table-title'>
												Gift when purchasing seasonal menu
											</td>
											<td>12 November, 2023</td>
										</tr>
										<tr>
											<td>2</td>
											<td class='table-title'>
												KRIKEY Eats membership promotion information
											</td>
											<td>26 September, 2023</td>
										</tr>
										<tr>
											<td>1</td>
											<td class='table-title'>Happy Cheuseok 2023!</td>
											<td>1 September, 2023</td>
										</tr>
									</tbody>
								</Table>
								<Pagination className='justify-content-center pg-black'>
									<Pagination.Prev />
									<Pagination.Item active>{1}</Pagination.Item>
									<Pagination.Item>{2}</Pagination.Item>
									<Pagination.Next />
								</Pagination>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Events;

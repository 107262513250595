import '../reset.scss';
import './menu.scss';

import { useState } from 'react';

import { useTranslation } from 'react-i18next';

function Menu04() {
	const { t } = useTranslation();

	return (
		<div className='menu-paragraph'>
			<div className='menu-paragraph-image menu-04' />
			<div className='menu-paragraph-content'>
				{/* MINI BAGELS */}

				<div className='menu-paragraph-content-block coldbeverages'>
					<div className='menu-paragraph-content-block-column'>
						<h3 className='menu-paragraph-content-block-title'>
							COLD BEVERAGES
						</h3>
						<div className='menu-paragraph-content-block-detail'>
							<div className='menu-paragraph-content-block-detail-item-head'>
								<p className='menu-paragraph-content-block-detail-Name'>
									SPARKLING WATER
								</p>
								<p className='menu-paragraph-content-block-detail-Price'>
									4,000
								</p>
							</div>
						</div>
						<div className='menu-paragraph-content-block-detail'>
							<div className='menu-paragraph-content-block-detail-item-head'>
								<p className='menu-paragraph-content-block-detail-Name'>
									ORANGE JUICE
								</p>
								<p className='menu-paragraph-content-block-detail-Price'>
									3,000
								</p>
							</div>
						</div>
						<div className='menu-paragraph-content-block-detail'>
							<div className='menu-paragraph-content-block-detail-item-head'>
								<p className='menu-paragraph-content-block-detail-Name'>
									GINGER BEER
								</p>
								<p className='menu-paragraph-content-block-detail-Price'>
									5,000?
								</p>
							</div>
						</div>
						<div className='menu-paragraph-content-block-detail'>
							<div className='menu-paragraph-content-block-detail-item-head'>
								<p className='menu-paragraph-content-block-detail-Name'>
									LEMON LIME BITTERS
								</p>
								<p className='menu-paragraph-content-block-detail-Price'>
									5,000?
								</p>
							</div>
						</div>
						<div className='menu-paragraph-content-block-detail'>
							<div className='menu-paragraph-content-block-detail-item-head'>
								<p className='menu-paragraph-content-block-detail-Name'>
									BLOOD ORANGE
								</p>
								<p className='menu-paragraph-content-block-detail-Price'>
									5,000?
								</p>
							</div>
						</div>
						<div className='menu-paragraph-content-block-detail'>
							<div className='menu-paragraph-content-block-detail-item-head'>
								<p className='menu-paragraph-content-block-detail-Name'>
									PINK GRAPEFRUIT
								</p>
								<p className='menu-paragraph-content-block-detail-Price'>
									5,000?
								</p>
							</div>
						</div>
					</div>
					<div className='menu-paragraph-content-block-column'>
						<h3 className='menu-paragraph-content-block-title'>ㅤ</h3>
						<div className='menu-paragraph-content-block-detail'>
							<div className='menu-paragraph-content-block-detail-item-head'>
								<p className='menu-paragraph-content-block-detail-Name'>
									NON-ALCOHOLIC BEER
								</p>
								<p className='menu-paragraph-content-block-detail-Price'>
									5,000?
								</p>
							</div>
						</div>
						<div className='menu-paragraph-content-block-detail'>
							<div className='menu-paragraph-content-block-detail-item-head'>
								<p className='menu-paragraph-content-block-detail-Name'>
									BOTTLED WATER
								</p>
								<p className='menu-paragraph-content-block-detail-Price'>
									2,000
								</p>
							</div>
						</div>
						<div className='menu-paragraph-content-block-detail'>
							<div className='menu-paragraph-content-block-detail-item-head'>
								<p className='menu-paragraph-content-block-detail-Name'>
									COCA-COLA
								</p>
								<p className='menu-paragraph-content-block-detail-Price'>
									3,000?
								</p>
							</div>
						</div>
						<div className='menu-paragraph-content-block-detail'>
							<div className='menu-paragraph-content-block-detail-item-head'>
								<p className='menu-paragraph-content-block-detail-Name'>
									COKE ZERO
								</p>
								<p className='menu-paragraph-content-block-detail-Price'>
									3,000?
								</p>
							</div>
						</div>
						<div className='menu-paragraph-content-block-detail'>
							<div className='menu-paragraph-content-block-detail-item-head'>
								<p className='menu-paragraph-content-block-detail-Name'>
									SPRITE
								</p>
								<p className='menu-paragraph-content-block-detail-Price'>
									3,000?
								</p>
							</div>
						</div>
						<div className='menu-paragraph-content-block-detail'>
							<div className='menu-paragraph-content-block-detail-item-head'>
								<p className='menu-paragraph-content-block-detail-Name'>
									CHILSUNG
								</p>
								<p className='menu-paragraph-content-block-detail-Price'>
									2,000?
								</p>
							</div>
						</div>
					</div>
				</div>

				{/* SPREADS */}

				<div className='menu-paragraph-content-block alcoholicbeverages'>
					<h3 className='menu-paragraph-content-block-title'>
						ALCOHOLIC BEVERAGES
					</h3>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								JEJU BEER FLAVOR 1
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>4,000</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								JEJU BEER FLAVOR 2
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>4,000</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								LOCAL CRAFT BEER 1
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>
								7,000?
							</p>
						</div>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								LOCAL CRAFT BEER 2
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>
								7,000?
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Menu04;

import '../reset.scss';
import './shop.scss';

import { Row, Col, Button } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

import { useTranslation } from 'react-i18next';

function Shop() {
	const { t } = useTranslation();

	return (
		<>
			<div id='shop-contents'>
				<div className='shop-main'>
					<div className='shop-main-banner'>
						<h1>SHOP ONLINE</h1>
					</div>

					<div className='shop-list'>
						<div className='shop-list-row'>
							<div className='shop-list-item'>
								<div className='shop-list-item-image mug' />
								<div className='shop-list-item-detail'>
									<h4 className='shop-list-item-detail-title'>
										The Krikey Eats Mug
									</h4>
									<p className='shop-list-item-detail-price'>9,000 KRW</p>
									<Button variant='dark'>Add to Cart</Button>
								</div>
							</div>
							<div className='shop-list-item'>
								<div className='shop-list-item-image tumbler' />
								<div className='shop-list-item-detail'>
									<h4 className='shop-list-item-detail-title'>
										Limited Edition Tumbler
									</h4>
									<p className='shop-list-item-detail-price'>12,000 KRW</p>
									<Button variant='dark'>Add to Cart</Button>
								</div>
							</div>
							<div className='shop-list-item'>
								<div className='shop-list-item-image ecobag' />
								<div className='shop-list-item-detail'>
									<h4 className='shop-list-item-detail-title'>
										Signature Eco Bag
									</h4>
									<p className='shop-list-item-detail-price'>7,000 KRW</p>
									<Button variant='dark'>Add to Cart</Button>
								</div>
							</div>
							<div className='shop-list-item'>
								<div className='shop-list-item-image siliconetumbler' />
								<div className='shop-list-item-detail'>
									<h4 className='shop-list-item-detail-title'>
										Collapsible Silicone Tumbler
									</h4>
									<p className='shop-list-item-detail-price'>9,000 KRW</p>
									<Button variant='dark'>Add to Cart</Button>
								</div>
							</div>
						</div>

						<div className='shop-list-row'>
							<div className='shop-list-item'>
								<div className='shop-list-item-image handletumbler' />
								<div className='shop-list-item-detail'>
									<h4 className='shop-list-item-detail-title'>
										Tumbler with a handle
									</h4>
									<p className='shop-list-item-detail-price'>13,000 KRW</p>
									<Button variant='dark'>Add to Cart</Button>
								</div>
							</div>
							<div className='shop-list-item'>
								<div className='shop-list-item-image thermaltumbler' />
								<div className='shop-list-item-detail'>
									<h4 className='shop-list-item-detail-title'>
										Thermal Tumbler
									</h4>
									<p className='shop-list-item-detail-price'>15,000 KRW</p>
									<Button variant='dark'>Add to Cart</Button>
								</div>
							</div>
							<div className='shop-list-item'>
								<div className='shop-list-item-image nettedecobag' />
								<div className='shop-list-item-detail'>
									<h4 className='shop-list-item-detail-title'>
										Netted Shopping Ecobag
									</h4>
									<p className='shop-list-item-detail-price'>11,000 KRW</p>
									<Button variant='dark'>Add to Cart</Button>
								</div>
							</div>
							<div className='shop-list-item'>
								<div className='shop-list-item-image apron' />
								<div className='shop-list-item-detail'>
									<h4 className='shop-list-item-detail-title'>
										The Krikey Eats Apron
									</h4>
									<p className='shop-list-item-detail-price'>15,000 KRW</p>
									<Button variant='dark'>Add to Cart</Button>
								</div>
							</div>
						</div>

						<div className='shop-list-row'>
							<div className='shop-list-item'>
								<div className='shop-list-item-image cap' />
								<div className='shop-list-item-detail'>
									<h4 className='shop-list-item-detail-title'>
										The Krikey Eats Cap
									</h4>
									<p className='shop-list-item-detail-price'>15,000 KRW</p>
									<Button variant='dark'>Add to Cart</Button>
								</div>
							</div>
							<div className='shop-list-item'>
								<div className='shop-list-item-image tshirts' />
								<div className='shop-list-item-detail'>
									<h4 className='shop-list-item-detail-title'>
										Bagel T-Shirts
									</h4>
									<p className='shop-list-item-detail-price'>30,000 KRW</p>
									<Button variant='dark'>Add to Cart</Button>
								</div>
							</div>
							<div className='shop-list-item'>
								<div className='shop-list-item-image towel' />
								<div className='shop-list-item-detail'>
									<h4 className='shop-list-item-detail-title'>
										The Krikey Eats Kitchen Towels
									</h4>
									<p className='shop-list-item-detail-price'>4,000 KRW</p>
									<Button variant='dark'>Add to Cart</Button>
								</div>
							</div>
							<div className='shop-list-item'>
								<div className='shop-list-item-image umbrella' />
								<div className='shop-list-item-detail'>
									<h4 className='shop-list-item-detail-title'>
										The Krikey Eats Umbrella
									</h4>
									<p className='shop-list-item-detail-price'>15,000 KRW</p>
									<Button variant='dark'>Add to Cart</Button>
								</div>
							</div>
						</div>
						<div className='shop-list-row'>
							<div className='shop-list-item'>
								<div className='shop-list-item-image diary' />
								<div className='shop-list-item-detail'>
									<h4 className='shop-list-item-detail-title'>
										The Krikey Eats Diary
									</h4>
									<p className='shop-list-item-detail-price'>15,000 KRW</p>
									<Button variant='dark'>Add to Cart</Button>
								</div>
							</div>
							<div className='shop-list-item'>
								<div className='shop-list-item-image coolerbag' />
								<div className='shop-list-item-detail'>
									<h4 className='shop-list-item-detail-title'>Cooler Bag</h4>
									<p className='shop-list-item-detail-price'>32,000 KRW</p>
									<Button variant='dark'>Add to Cart</Button>
								</div>
							</div>
							<div className='shop-list-item'>
								<div className='shop-list-item-image beans' />
								<div className='shop-list-item-detail'>
									<h4 className='shop-list-item-detail-title'>
										The Krikey Eats Coffee Beans
									</h4>
									<p className='shop-list-item-detail-price'>13,000 KRW</p>
									<Button variant='dark'>Add to Cart</Button>
								</div>
							</div>
							<div className='shop-list-item'>
								<div className='shop-list-item-image bagelslicer' />
								<div className='shop-list-item-detail'>
									<h4 className='shop-list-item-detail-title'>Bagel Slicer</h4>
									<p className='shop-list-item-detail-price'>20,000 KRW</p>
									<Button variant='dark'>Add to Cart</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Shop;

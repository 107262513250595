import './navi.scss';

import React, { useState } from 'react';

import { Navbar, Nav } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

function Navi() {
	return (
		<div id='navi-contents'>
			<div className='nav-menu'>
				<Navbar>
					<Nav>
						<div className='nav-menu-text left'>
							<HashLink to='/about#'>About Us</HashLink>
							<HashLink to='/menu#'>Our Menu</HashLink>
							<HashLink to='/find#'>Locations</HashLink>
							<HashLink to='/shop#'>Shop Online</HashLink>
						</div>
						<HashLink to='/#' className='nav-logo'></HashLink>
						<div className='nav-menu-text right'>
							<HashLink to='/catering#'>Catering</HashLink>
							<HashLink to='/franchising#'>Franchising</HashLink>
							<HashLink to='/press#'>Press</HashLink>
							<HashLink to='/events#'>Events</HashLink>
						</div>
					</Nav>
				</Navbar>
			</div>
		</div>
	);
}

export default Navi;

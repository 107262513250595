import '../reset.scss';
import './catering.scss';

import { Form, Row, Col, Button } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

import { useTranslation } from 'react-i18next';

function Catering() {
	const { t } = useTranslation();

	return (
		<>
			<div id='catering-contents'>
				<div className='catering-main'>
					<div className='catering-main-banner'>
						<h1>CATERING</h1>
					</div>

					<div className='catering-contents'>
						<div className='catering-contents-title'>
							<h2>
								Get in touch with us to place your Party Box order
								<br />
								by completing this order inquiry form below.
							</h2>
						</div>
						<div className='catering-contents-body'>
							<div className='catering-contents-body-pricelist'>
								<div className='pricelist-item'>
									<div className='pricelist-item-title'>
										<h3>BRUNCH PARTY</h3>
									</div>
									<div className='pricelist-item-underline' />
									<div className='pricelist-item-detail'>
										<div className='pricelist-item-detail-description'>
											<p>
												Pre-made breakfast menu bagels to your request.
												Combinations of G’Day and Good Morning.
											</p>
										</div>
										<div className='pricelist-item-detail-price-third'>
											<div className='pricelist-item-detail-price-third-item'>
												<h5>6 BAGELS</h5>
												<p>27,000</p>
												<p>25,500</p>
											</div>
											<div className='pricelist-item-detail-price-third-item'>
												<h5>12 BAGELS</h5>
												<p>54,000</p>
												<p>50,000</p>
											</div>
											<div className='pricelist-item-detail-price-third-item'>
												<h5>24 BAGELS</h5>
												<p>108,000</p>
												<p>91,800</p>
											</div>
										</div>
										<div className='pricelist-item-detail-description'>
											<p>* Prices exclude price of beverages.</p>
										</div>
									</div>
								</div>
								<div className='pricelist-item'>
									<div className='pricelist-item-title'>
										<h3>ALL-DAY PARTY</h3>
									</div>
									<div className='pricelist-item-underline' />
									<div className='pricelist-item-detail'>
										<div className='pricelist-item-detail-description'>
											<p>
												Pre-made assortment of all day menu bagels to your
												request. Combinations of The Sydneysider, The New
												Yorker, The Bostonian, and The Londoner.
											</p>
										</div>
										<div className='pricelist-item-detail-price-third'>
											<div className='pricelist-item-detail-price-third-item'>
												<h5>6 BAGELS</h5>
												<p>45,000</p>
												<p>42,700</p>
											</div>
											<div className='pricelist-item-detail-price-third-item'>
												<h5>12 BAGELS</h5>
												<p>90,000</p>
												<p>83,700</p>
											</div>
											<div className='pricelist-item-detail-price-third-item'>
												<h5>24 BAGELS</h5>
												<p>180,000</p>
												<p>153,000</p>
											</div>
										</div>
										<div className='pricelist-item-detail-description'>
											<p>* Prices exclude price of beverages.</p>
										</div>
									</div>
								</div>
								<div className='pricelist-item'>
									<div className='pricelist-item-title'>
										<h3>DESIGN YOUR PARTY</h3>
									</div>
									<div className='pricelist-item-underline' />
									<div className='pricelist-item-detail'>
										<div className='pricelist-item-detail-step'>
											<h5>CHOOSE YOUR BAGELS</h5>
											<p>Choose from any of our bagels.</p>
										</div>
										<div className='pricelist-item-detail-plus'>
											<h5>+</h5>
										</div>
										<div className='pricelist-item-detail-step'>
											<h5>CHOOSE YOUR FILLING</h5>
											<p>
												Choose from any of our spreads
												<br />
												and all day menus.
											</p>
										</div>
										<div className='pricelist-item-detail-plus'>
											<h5>+</h5>
										</div>
										<div className='pricelist-item-detail-step'>
											<h5>SELECT NUMBER OF PEOPLE</h5>
											<p>
												Select the number of people
												<br />
												you’re feeding.
											</p>
										</div>
									</div>
								</div>
								<div className='pricelist-item'>
									<div className='pricelist-item-title'>
										<h3>BEVERAGES</h3>
									</div>
									<div className='pricelist-item-underline' />
									<div className='pricelist-item-detail'>
										<div className='pricelist-item-detail-menu'>
											<div className='pricelist-item-detail-menu-item'>
												<p>LONG BLACK (1 CUP)</p>
												<p>4,800</p>
											</div>
											<div className='pricelist-item-detail-menu-item'>
												<p>6 CUPS</p>
												<p>27,000</p>
											</div>
											<div className='pricelist-item-detail-menu-item'>
												<p>12 CUPS</p>
												<p>54,000</p>
											</div>
											<div className='pricelist-item-detail-menu-item'>
												<p>24 CUPS</p>
												<p>104,000</p>
											</div>
										</div>
										<div className='pricelist-item-detail-menu'>
											<div className='pricelist-item-detail-menu-item'>
												<p>FLAT WHITE (1 CUP)</p>
												<p>5,500</p>
											</div>
											<div className='pricelist-item-detail-menu-item'>
												<p>6 CUPS</p>
												<p>31,000</p>
											</div>
											<div className='pricelist-item-detail-menu-item'>
												<p>12 CUPS</p>
												<p>61,000</p>
											</div>
											<div className='pricelist-item-detail-menu-item'>
												<p>24 CUPS</p>
												<p>119,000</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='catering-contents-body-form'>
								<div className='catering-contents-body-form-title'>
									<p>
										Organizing breakfast for everyone at the office?
										<br />
										Planning to hold an event for work?
										<br />
										Or just want to have a fun picnic by the river with some
										friends?
									</p>
									<p>
										We’re here with our Party Boxes that
										<br />
										will help you build a delicious meal
										<br />
										everyone will talk about!
									</p>
								</div>
								<div className='catering-contents-body-form-body'>
									<Form>
										<Row>
											<Form.Group
												className='mb-3'
												as={Col}
												controlId='cateringForm.ControlInputName'>
												<Form.Control
													required
													type='text'
													placeholder='Name'
													maxLength='20'
												/>
											</Form.Group>

											<Form.Group
												className='mb-3'
												as={Col}
												controlId='cateringForm.ControlInputPhone'>
												<Form.Control
													required
													type='text'
													placeholder='Phone'
													maxLength='20'
												/>
											</Form.Group>
										</Row>
										<Form.Group
											className='mb-3'
											as={Col}
											controlId='cateringForm.ControlInputMail'>
											<Form.Control
												required
												type='text'
												placeholder='E-Mail'
												maxLength='50'
											/>
										</Form.Group>
										<Form.Group
											className='mb-3'
											as={Col}
											controlId='cateringForm.ControlInputAdress'>
											<Form.Control
												required
												type='text'
												placeholder='Address'
												maxLength='50'
											/>
										</Form.Group>
										<Form.Group
											className='mb-3'
											controlId='cateringForm.ControlTextareaMessage'>
											<Form.Control
												required
												name='message'
												as='textarea'
												maxLength='200'
												rows={3}
												placeholder='Comments'
											/>
										</Form.Group>
										<Button type='submit' variant='dark' size='lg'>
											Send
										</Button>
									</Form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Catering;

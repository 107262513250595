import '../reset.scss';
import './menu.scss';

import { useState } from 'react';

import { useTranslation } from 'react-i18next';

function Menu02() {
	const { t } = useTranslation();

	return (
		<div className='menu-paragraph'>
			<div className='menu-paragraph-image menu-02' />
			<div className='menu-paragraph-content'>
				{/* MINI BAGELS MENU */}

				<div className='menu-paragraph-content-block'>
					<h3 className='menu-paragraph-content-block-title'>
						MINI BAGELS MENU
					</h3>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								THE SYDNEYSIDER
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>7,500</p>
						</div>
						<p className='menu-paragraph-content-block-detail-Description'>
							Smoked salmon, plain cream cheese, cucumber, pickled onions,
							capers on your choice of core bagel or pretzel.
						</p>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								THE NEW YORKER
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>7,500</p>
						</div>
						<p className='menu-paragraph-content-block-detail-Description'>
							Pastrami, spinach, dill pickles, cucumber, red onion, signature
							sauce on your choice of core bagel or pretzel.
						</p>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								THE BOSTONIAN
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>7,500</p>
						</div>
						<p className='menu-paragraph-content-block-detail-Description'>
							Salami, spinach, cheese, avocado, carrots, red onions on your
							choice of core bagel or pretzel.
						</p>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								THE LONDONER
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>7,500</p>
						</div>
						<p className='menu-paragraph-content-block-detail-Description'>
							Pastrami, sauteed onions, dill pickles, cheese on your choice of
							core bagel or pretzel.
						</p>
					</div>
				</div>

				{/* MINI SAUSAGE MENU */}

				<div className='menu-paragraph-content-block'>
					<h3 className='menu-paragraph-content-block-title'>
						MINI SAUSAGE MENU
					</h3>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								THE CLASSIC
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>5,500</p>
						</div>
						<p className='menu-paragraph-content-block-detail-Description'>
							Your choice of sausage with sauteed onions, gherkins, cheese on
							our signature brioche bun.
						</p>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								THE ULTIMATE
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>7,500</p>
						</div>
						<p className='menu-paragraph-content-block-detail-Description'>
							Your choice of sausage, red cabbage, pickled onions, carrots,
							avocado on our signature brioche bun.
						</p>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								THE ALL-ROUNDER
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>4,000</p>
						</div>
						<p className='menu-paragraph-content-block-detail-Description'>
							Your choice of sausage and mustard on our signature brioche bun.
						</p>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								TRY ALL THREE
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>
								15,500
							</p>
						</div>
						<p className='menu-paragraph-content-block-detail-Description'>
							Have one of each of our All Day Sausage menus.
						</p>
					</div>
				</div>

				{/* KIDS */}

				<div className='menu-paragraph-content-block'>
					<h3 className='menu-paragraph-content-block-title'>
						KIDS (&lt;8 YEARS OLD)
					</h3>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								SUNSHINE
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>3,800</p>
						</div>
						<p className='menu-paragraph-content-block-detail-Description'>
							Ham, cheese, spinach, cucumber, avocado on a mini plain bagel.
						</p>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								LITTLE BITE
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>3,800</p>
						</div>
						<p className='menu-paragraph-content-block-detail-Description'>
							Ham, cheese, spinach, cucumber, avocado on a mini classic pretzel.
						</p>
					</div>
					<div className='menu-paragraph-content-block-detail'>
						<div className='menu-paragraph-content-block-detail-item-head'>
							<p className='menu-paragraph-content-block-detail-Name'>
								BABYCINNO
							</p>
							<p className='menu-paragraph-content-block-detail-Price'>1,500</p>
						</div>
						<p className='menu-paragraph-content-block-detail-Description'>
							Warmed up, frothy whole milk.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Menu02;
